<div class="container">
  <div class="footer">
    <p>© 2020 Luftsportverein Münsingen e.V.</p>
    <div class="category">
      <a href="https://www.lsv-muensingen.de/datenschutz">Datenschutz</a>
      <a href="https://www.lsv-muensingen.de/impressum">Impressum</a>
      <!-- <a>Vereinsleitung</a> -->
    </div>
  </div>
</div>
