<div class="container">
  <div class="gridMain">
    <div class="description descriptionFliegen">
      <div class="description-container">
        <h3>Fliegen</h3>
        <h1>Erleben Sie es selbst!</h1>
        <div class="gap-line"></div>
        <p>Am Himmel schweben, wie ein Vogel durch die Luft gleiten und den
          Alltag unter sich zurücklassen. Eine Chance, die eigenen Grenzen
          zu erfahren und die dritte Dimension hautnah zu erleben.</p>
        <p class="padding-top">Besuchen Sie uns doch einfach und fliegen einmal selbst mit.</p>
      </div>
    </div>
    <div class="img imgFliegen">
      <img src="assets/images/mitfliegen.jpg" class="hide-on-init" animateOnScroll animationName="animate__animated animate__fadeInBottomRight animate__fast">
    </div>
    <!-- only seen on mobile devices -->
    <div *ngIf="isMobile" name="fliegerklause" class="description descriptionWirtschaft">
      <div class="description-container">
        <h3>Fliegerklause</h3>
        <h1>Speisen und Getränke</h1>
        <div class="gap-line"></div>
        <p>An Sonn- und Feiertagen ist unsere frisch renovierte Fliegerklause bewirtet.</p>
      </div>
    </div>
    <div class="img imgWirtschaft">
      <img id="wirtschaft" src="assets/images/wirtschaft.jpg" class="hide-on-init" animateOnScroll animationName="animate__animated animate__fadeInBottomLeft animate__fast">
      <img class="hidden hide-on-init" src="assets/images/wirtschaft.jpg">
      <img id="wirtschaft2" src="assets/images/wirtschaft2.jpg" class="hide-on-init" animateOnScroll animationName="animate__animated animate__fadeInBottomLeft animate__delay-1s animate__fast">
    </div>
    <div *ngIf="!isMobile" name="fliegerklause" class="description descriptionWirtschaft">
      <div class="description-container">
        <h3>Fliegerklause</h3>
        <h1>Speisen und Getränke</h1>
        <div class="gap-line"></div>
        <p>An Sonn- und Feiertagen, während der Flugsaison (April bis Oktober), ist unsere frisch renovierte Fliegerklause bewirtet.</p>
      </div>
    </div>
    <div class="description" id="ausbildung">
      <div class="description-container">
        <h3>Ausbildung</h3>
        <h1>Fliegen lernen!</h1>
        <div class="gap-line"></div>
        <p>Verwirklichen Sie sich den Traum vom fliegen und werden sie Pilot. Bei uns am Fluggelände bilden wir Vereinsmitglieder in allen Sparten:
          Segelflug, Ulltraleicht, Reisemotorsegler und Motorflug aus. Bei Interesse laden wir Sie gerne zu einem Schnupperflug mit unseren ehrenamtlichen Fluglehrer ein.</p>
      </div>
    </div>
    <div class="video img">
      <img class="hidden hide-on-init" src="assets/images/mitfliegen.jpg">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/D3-s7hWb6rA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</div>
