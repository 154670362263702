import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
  public isMobile = false;
  constructor() { }

  ngOnInit(): void {
    (window.innerWidth < window.innerHeight) ? this.isMobile = true : this.isMobile = false;
  }

}
