<div class="container">
  <h1><i class="fas fa-video flip"></i>  Webcam</h1>
  <div class="header-line"></div>
  <div class="grid">
    <div class="webcamBox webcamWest">
      <a [href]="linkWest" target="_blank">
        <img [src]="linkWest" (window:resize)="onResize($event)" alt="Webcam West" #webcamwest>
      </a>
      <!-- (error)="backupUrl(linkWest)" -->
    </div>
    <div class="webcamOverview">
      <img src="/assets/images/webcamClipartN.png" alt="webcamNA">
    </div>
    <div class="webcamBox webcamOst">
      <a [href]="linkOst" target="_blank">
        <img [src]="linkOst" (window:resize)="onResize($event)" alt="Webcam Ost" #webcamost>
      </a>
    </div>
  </div>
</div>


