import {Planes} from './planes.model';

export const PLANE_DESCRIPTION = ['Kennzeichen', 'Hersteller', 'Baujahr', 'Spannweite', 'Sitzplätze', 'max. Abfluggewicht', 'max. Geschwindigkeit'];

export const MOTOR_PLANE_LIST: Planes[] = [
  {
    type: 'Motorflugzeug',
    name: 'Robin DR 400/180',
    id: 'D-EEIA',
    brand: 'Robin Aircraft',
    constructionYear: 1986,
    wingSpan: '8,7 m',
    seats: 4,
    mtow: '1000 kg',
    vne: '310 km/h',
    img: '/assets/images/planes/jodel.jpg',
  },
  {
    type: 'Reisemotorsegler',
    name: 'HK 36 Super Dimona',
    id: 'D-KDOT',
    brand: 'Diamond Aircraft',
    constructionYear: 1996,
    wingSpan: '16,3 m',
    seats: 2,
    mtow: '770 kg',
    vne: '261 km/h',
    img: '/assets/images/planes/dimona.jpg',
  },
  {
    type: 'Ultraleichtflugzeug',
    name: 'Dynamic WT 9',
    id: 'D-MECM',
    brand: 'Aerospool',
    constructionYear: 2014,
    wingSpan: '9 m',
    seats: 2,
    mtow: '475 kg',
    vne: '280 km/h',
    img: '/assets/images/planes/dynamic.jpg',
  },
  {
    type: 'Ultraleichtflugzeug',
    name: 'C 42',
    id: 'D-MPLO',
    brand: 'Comco Ikarus',
    constructionYear: 1998,
    wingSpan: '9,4 m',
    seats: 2,
    mtow: '475 kg',
    vne: '180 km/h',
    img: '/assets/images/planes/c42.jpg',
  }
];

export const GLIDER_PLANE_LIST: Planes[] = [
  {
    type: 'Segelflugzeug',
    name: 'Duo Discus XLT',
    id: 'D-KUEN',
    brand: 'Schempp-Hirth',
    constructionYear: 2009,
    wingSpan: '20 m',
    seats: 2,
    mtow: '750 kg',
    vne: '263 km/h',
    img: '/assets/images/planes/duoDiscus.jpg',
  },
  {
    type: 'Segelflugzeug',
    name: 'ASK 21',
    id: 'D-0779',
    brand: 'Alexander Schleicher',
    constructionYear: 0,
    wingSpan: '17 m',
    seats: 2,
    mtow: '600 kg',
    vne: '280 km/h',
    img: '/assets/images/planes/ask21.jpg'
  },
  {
    type: 'Segelflugzeug',
    name: 'ASW 19',
    id: 'D-7959',
    brand: 'Alexander Schleicher',
    constructionYear: 1978,
    wingSpan: '15 m',
    seats: 1,
    mtow: '450 kg',
    vne: '255 km/h',
    img: '/assets/images/planes/asw19TX.jpg'
  },
  {
    type: 'Segelflugzeug',
    name: 'ASW 19',
    id: 'D-7970',
    brand: 'Alexander Schleicher',
    constructionYear: 1979,
    wingSpan: '15 m',
    seats: 1,
    mtow: '450 kg',
    vne: '255 km/h',
    img: '/assets/images/planes/asw19AF.jpg'
  },
  {
    type: 'Segelflugzeug',
    name: 'Astir CS',
    id: 'D-4273',
    brand: 'Grob Flugzeugbau',
    constructionYear: 1977,
    wingSpan: '15 m',
    seats: 1,
    mtow: '450 kg',
    vne: '250 km/h',
    img: '/assets/images/planes/astir.jpg'
  },
  {
    type: 'Segelflugzeug',
    name: 'Ka 6 CR',
    id: 'D-9131',
    brand: 'Alexander Schleicher',
    constructionYear: 1966,
    wingSpan: '15 m',
    seats: 1,
    mtow: '300 kg',
    vne: '200 km/h',
    img: '/assets/images/planes/ka6.jpg'
  },
];
