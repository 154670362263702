<div class="container">
  <h2>Segelflugzeuge</h2>
  <swiper></swiper>
  <div  class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper">
      <div *ngFor="let glider of gliderList" class="swiper-slide">
        <app-card [description]="gliderDescription" [details]="glider"></app-card>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</div>

