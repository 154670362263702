import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit {
  @Input('parentData') public parentDataChild;
  @Output() public sendEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  closeMenu() {
    this.sendEvent.emit();
  }

}
