import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {
  public isMobile = false;
  constructor() { }

  ngOnInit(): void {
    this.isMobile = (window.innerWidth <= 991) ? true : this.isMobile;
  }

}
