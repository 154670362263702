<div class="container">
  <app-headline [headline]="'Flugzeugpark'"></app-headline>
  <div class="flugzeuge">
    <div *ngFor="let plane of motorList">
      <h2>{{plane.type}}</h2>
      <app-card [description]="planeDescription" [details]="plane"></app-card>
    </div>
  </div>
  <swiper *ngIf="hideSwiper"></swiper>
  <div *ngIf="hideSwiper" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper">
      <div *ngFor="let plane of motorList" class="swiper-slide">
        <h2>{{plane.type}}</h2>
        <app-card [description]="planeDescription" [details]="plane"></app-card>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</div>
