import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ImpressumComponent} from './routes/impressum/impressum.component';
import {DatenschutzComponent} from './routes/datenschutz/datenschutz.component';
import {GeschichteComponent} from './routes/geschichte/geschichte.component';
import {HomeComponent} from './routes/home/home.component';
import {AusbildungComponent} from './routes/ausbildung/ausbildung.component';
import {UmfrageComponent} from './routes/umfrage/umfrage.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'datenschutz', component: DatenschutzComponent},
  { path: 'geschichte', component: GeschichteComponent},
  { path: 'ausbildung', component: AusbildungComponent},
  { path: 'umfrage', component: UmfrageComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 55],
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [ImpressumComponent, DatenschutzComponent, GeschichteComponent];
