import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {AppRoutingModule, routingComponents} from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './card/card.component';
import { MotorPlaneCardsComponent } from './motor-plane-cards/motor-plane-cards.component';
import { GliderPlaneCardsComponent } from './glider-plane-cards/glider-plane-cards.component';
import { WebcamCardsComponent } from './webcam-cards/webcam-cards.component';
import { AnflugComponent } from './anflug/anflug.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { VeranstaltungenComponent } from './veranstaltungen/veranstaltungen.component';
import { HeadlineComponent } from './headline/headline.component';
import { MainContentComponent } from './main-content/main-content.component';
import { StartComponent } from './start/start.component';
import {HttpClientModule} from '@angular/common/http';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { HomeComponent } from './routes/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AusbildungComponent } from './routes/ausbildung/ausbildung.component';
import { BannerComponent } from './banner/banner.component';
import { LsvLibModule } from '../../../lsv-lib/src/lib/lsv-lib.module';
import { UmfrageComponent } from './routes/umfrage/umfrage.component';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 0,
  spaceBetween: 5,
  slidesPerView: 1,
};

@NgModule({
  declarations: [
    AppComponent,
    MotorPlaneCardsComponent,
    CardComponent,
    GliderPlaneCardsComponent,
    WebcamCardsComponent,
    WebcamCardsComponent,
    AnflugComponent,
    VeranstaltungenComponent,
    HeadlineComponent,
    MainContentComponent,
    StartComponent,
    routingComponents,
    HomeComponent,
    AusbildungComponent,
    BannerComponent,
    UmfrageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    HttpClientModule,
    AnimateOnScrollModule.forRoot(),
    BrowserAnimationsModule,
    DeviceDetectorModule,
    LsvLibModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
