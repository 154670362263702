import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.css']
})
export class HamburgerComponent implements OnInit {
  @Input('parentData') public clicked;
  //clicked = false;
  constructor() { }

  ngOnInit(): void {
    console.log('child: ' + this.clicked);
  }
  // public addClass() {
  //   this.clicked = !this.clicked;
  // }
}
