import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-anfahrt',
  templateUrl: './anfahrt.component.html',
  styleUrls: ['./anfahrt.component.css']
})
export class AnfahrtComponent implements OnInit {
  public isMobile = false;
  constructor() { }

  ngOnInit(): void {
    this.isMobile = (window.innerWidth <= 991) ? true : false;
  }

}
