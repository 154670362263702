import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-umfrage',
  templateUrl: './umfrage.component.html',
  styleUrls: ['./umfrage.component.css']
})
export class UmfrageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSc3vC8-XsPl-vHer9VYTLoQMQnfHnFE0VFmef3SMBB_zH7iJw/viewform?usp=sf_link';
  }

}
