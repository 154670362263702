import {Component, Input, OnInit} from '@angular/core';
import {Planes} from '../plane/planes.model';
import {animate, keyframes, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {from} from 'rxjs';
import * as animations from '../animations';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  animations: [
    trigger('growInShrinkOut', [
      transition(':enter', useAnimation(animations.growIn)),
      transition(':leave', useAnimation(animations.shrinkOut)),
    ]),
    trigger('fadeInAndOut', [
      transition(':enter', animations.useSlideFadeInAnimation(undefined, '0px')),
      transition(':leave', animations.useSlideFadeOutAnimation( undefined,  '0px')),
    ]),
  ]
})
export class CardComponent implements OnInit {
  @Input() public description: [];
  @Input() public details: Planes;
  public buttonContent = 'Read more';
  public showContent = false;
  planeDetails;

  constructor() { }

  ngOnInit(): void {
    this.planeDetails = Object.values(this.details);
  }

  readMore() {
    this.showContent = !this.showContent;
    (this.buttonContent === 'Read more') ? this.buttonContent = 'Hide' : this.buttonContent = 'Read more';
  }

}


