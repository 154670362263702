<!-- google map api
<div>
  <google-map height="360" width="640" [zoom]="14" [center]="myLatLing"></google-map>
</div>
-->
<div class="container">
  <div class="grid">
    <div class="description descriptionAnflug">
      <div class="description-container">
        <h1>Anflug</h1>
        <div class="gap-line"></div>
        <div class="line">
          <p class="TDlinks noWidth">Piloteninformation:</p>
          <p class="TDrechts noWidth">Frequenz <span class="attention">121.215 Mhz</span>. Call Sign: Münsingen Info. Bezugspunkt: 48°24'37''N 9°26'32''E</p>
        </div>
        <div class="line">
          <p class="TDlinks noWidth">Pflichtmeldung:</p>
          <p class="TDrechts noWidth">5 Minuten vor Flugplatz</p>
        </div>
        <div class="line">
          <p class="TDlinks noWidth">Platzrunde:</p>
          <p class="TDrechts noWidth">Motorflug SÜD 3300ft, Überfliegen der Ortschaft <span class="attention">Dottingen</span> im Norden unterlassen!</p>
        </div>
        <div class="line">
          <p class="TDlinks noWidth">Segelflieger:</p>
          <p class="TDrechts noWidth"><span>sind jederzeit willkommen.</span> Rückschlepps sind evtl. auch an Werktagen möglich (<a routerLink="/impressum" [fragment]="'contact'">siehe Kontaktbereich</a>)</p>
        </div>
        <div class="line">
          <p class="TDlinks noWidth">Motorsegler<br>SEP und UL</p>
          <p class="TDrechts noWidth">können den Flugplatz per<span> PPR </span>anfliegen. Anfragen bitte vorab per Email oder telefonisch (<a routerLink="/impressum" [fragment]="'contact'">siehe Kontaktbereich</a>) oder an Sonn- und Feiertagen 5min. vorab über Funk</p>
        </div>
      </div>
    </div>
    <div class="anflugImg">
    </div>
  </div>
</div>
