import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CivilTwilightService {
  apiUrl = 'https://api.sunrise-sunset.org/json?lat=48.4085050&lng=9.43994&formatted=0';

  constructor(private http: HttpClient) { }

  getData(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }
}
