import {
  AfterViewChecked,
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {CivilTwilightService } from './civil-twilight.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)'
      })),
      state('out', style({
        transform: 'translateX(100%)'
      })),
      transition('in => out', animate('400ms ease-in')),
      transition('out => in', animate('500ms ease-out'))
    ]),
  ]


})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  constructor(private CivilTwilight: CivilTwilightService, private deviceService: DeviceDetectorService) {
    this.data = new Array<any>();
    this.getDataFromApi();
    this.getDevices();
  }
  public isSmall = false;
  private minutes = '0' + new Date().getUTCMinutes();
  private hours = '0' + new Date().getUTCHours();
  public time = this.hours.slice(-2) + ':' + this.minutes.slice(-2);
  private timer;
  data: Array<any>;
  civilTwilightBegin;
  civilTwilightEnd;
  openMobileMenu = false;
  public isMobile = false;
  deviceInfo = null;
  isMobileDevice;
  isTabletDevice;
  isDesktopDevice;
  menuState = 'out';
  mouseX;
  mouseY;
  mouseOverUTC = false;
  mouseOverDayStart = false;
  mouseOverDayEnd = false;

  ngOnInit(): void {
    this.timer = setInterval(() => {
      this.minutes = '0' + new Date().getUTCMinutes();
      this.hours = '0' + new Date().getUTCHours();
      this.time = this.hours.slice(-2) + ':' + this.minutes.slice(-2);
    }, 10000);

    this.isMobile = (window.innerWidth <= 991) ? true : false;
    (this.isMobile) ? this.isSmall = true : this.isSmall = false;
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  ngAfterViewChecked(): void {
  }

  @HostListener('window:scroll', []) onWindowScroll() {
      if (window.pageYOffset > 100 || this.isMobile) {
        this.isSmall = true;
      } else {
        this.isSmall = false;
      }
  }

  infoPosition(event) {
    this.mouseX = event.clientX - 200;
    this.mouseY = event.clientY + 10;
  }

  openMenu() {
    this.openMobileMenu = !this.openMobileMenu;
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  getDataFromApi() {
      this.CivilTwilight.getData().subscribe((data) => {
        this.data = data;
        this.civilTwilightBegin = data.results.civil_twilight_begin.substring(11, 16);
        this.civilTwilightEnd = data.results.civil_twilight_end.substring(11, 16);
      });
  }
  getDevices() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobileDevice = this.deviceService.isMobile();
    this.isTabletDevice = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

}

