import { NgModule } from '@angular/core';
import { LsvLibComponent } from './lsv-lib.component';
import { TestComponent } from './test/test.component';
import { HeaderComponent} from './header/header.component';
import { FooterComponent} from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { HamburgerComponent } from './header/hamburger/hamburger.component';
import { MobileMenuComponent } from './header/mobile-menu/mobile-menu.component';
import {CommonModule} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {AnfahrtComponent} from './anfahrt/anfahrt.component';


@NgModule({
  declarations: [
    LsvLibComponent,
    TestComponent,
    HeaderComponent,
    FooterComponent,
    HamburgerComponent,
    MobileMenuComponent,
    AnfahrtComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    HamburgerComponent,
    MobileMenuComponent,
    AnfahrtComponent
  ]
})
export class LsvLibModule { }
