import { Component, OnInit } from '@angular/core';
import { GLIDER_PLANE_LIST, PLANE_DESCRIPTION} from '../plane/planes.data';
import { Planes } from '../plane/planes.model';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-glider-plane-cards',
  templateUrl: './glider-plane-cards.component.html',
  styleUrls: ['./glider-plane-cards.component.css']
})
export class GliderPlaneCardsComponent implements OnInit {
  gliderList: Planes[] = GLIDER_PLANE_LIST;
  gliderDescription = PLANE_DESCRIPTION;
  public hideSwiper = false;
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 4,
    updateOnWindowResize: true,
    freeMode: true,
    slidesOffsetAfter: 350,
  };

  constructor() {
  }

  ngOnInit(): void {
      this.config = (window.innerWidth <= 350)
        ? {...this.config, width: 500, slidesPerView: 2}
        : (window.innerWidth <= 500)
        ? {...this.config, width: 600, slidesPerView: 2}
        : (window.innerWidth <= 700)
        ? {...this.config, width: 700, slidesPerView: 2}
        : (window.innerWidth <= 992)
        ? {...this.config, width: 800, slidesPerView: 2}
        : (window.innerWidth <= 1200)
        ? {...this.config, slidesPerView: 3}
        : {...this.config, navigation: true, pagination: {el: '.swiper-pagination', clickable: true}};
  }

}
