<div class="container">
  <div class="grid">
    <div *ngIf="isMobile" class="description descriptionAnfahrt">
      <div class="centerBox">
        <h1>Anfahrt</h1>
        <div class="gap-line"></div>
        <p>Sie erreichen das Seggelfluggelände Münsingen-Eisberg über die Reutlinger Str. K6701 zwischen Münsingen und Dottingen befindet sich die Flugplatzzufahrt.</p>
      </div>
      <img src="/assets/lsv-lib/images/zufahrt.png">
    </div>
    <div class="map-responsive">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16056.758875149602!2d9.434544717646189!3d48.410897578239805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479988cb073b3c17%3A0xb63022e0962570f6!2sSegelfluggel%C3%A4nde+M%C3%BCnsingen-Eisberg!5e0!3m2!1sde!2sde!4v1549981196165" width="640" height="360" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>
    <div *ngIf="!isMobile" class="description descriptionAnfahrt">
      <img src="/assets/lsv-lib/images/zufahrt.png" animateOnScroll animationName="animate__animated animate__zoomIn">
      <div class="centerBox">
        <h1>Anfahrt</h1>
        <div class="gap-line"></div>
        <p>Sie erreichen das Seggelfluggelände Münsingen-Eisberg über die Reutlinger Str. K6701 zwischen Münsingen und Dottingen befindet sich die Flugplatzzufahrt.</p>
      </div>
    </div>
  </div>
</div>
