import { AfterContentChecked, Component } from '@angular/core';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements AfterContentChecked {
  public headerWidth;
  public paddingTop;
  constructor() { }
  ngAfterContentChecked(): void {
    this.paddingTop = this.headerWidth / 7 + 'px';
  }

  receiveImgWidth($event) {
    this.headerWidth = $event;
  }
}
