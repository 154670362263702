import {AfterViewInit, Component, ElementRef, HostListener, OnChanges, OnInit, ViewChild} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-veranstaltungen',
  templateUrl: './veranstaltungen.component.html',
  styleUrls: ['./veranstaltungen.component.css']
})
export class VeranstaltungenComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    updateOnWindowResize: true,
    freeMode: true,
    slidesOffsetAfter: 450,
    slidesOffsetBefore: 100,
    spaceBetween: 50,
  };

  constructor() { }

  ngOnInit(): void {
    this.config = (window.innerWidth <= 350)
      ? {...this.config, width: 500, slidesOffsetBefore: 20, slidesOffsetAfter: 200,  spaceBetween: 20, slidesPerView: 2}
      : (window.innerWidth <= 500)
      ? {...this.config, width: 600, slidesOffsetBefore: 20, slidesOffsetAfter: 250,  spaceBetween: 20, slidesPerView: 2}
      : (window.innerWidth <= 700)
      ? {...this.config, width: 600, slidesOffsetBefore: 20, slidesOffsetAfter: 450,  spaceBetween: 20, slidesPerView: 2}
      : (window.innerWidth <= 992)
      ? {...this.config, width: 600, slidesOffsetBefore: 20, slidesOffsetAfter: 0,  spaceBetween: 20, slidesPerView: 2}
      : {...this.config, pagination: {el: '.swiper-pagination', clickable: true}};

  }

}
