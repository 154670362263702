<div class="mobileMenu">
  <p>Flugplatz</p>
  <a href="https://www.lsv-muensingen.de/home#anfahrt" (click)="closeMenu()">Anfahrt & Anflug</a>
  <a href="https://www.lsv-muensingen.de/home#fliegerklause" (click)="closeMenu()">Fliegerklause</a>
  <a href="https://www.lsv-muensingen.de/home#webcam" (click)="closeMenu()">Webcam</a>
  <p>Verein</p>
  <a href="https://www.lsv-muensingen.de/home#ausbildung" (click)="closeMenu()">Ausbildung</a>
  <a href="https://www.lsv-muensingen.de/home#flugzeugpark" (click)="closeMenu()">Flugzeugpark</a>
  <!-- <a href="#">Vereinsleitung</a>
  <a href="#">Geschichte</a> -->
  <p>Veranstaltungen</p>
  <a href="https://www.oldtimerfest-muensingen.de" (click)="closeMenu()">Oldtimertreffen</a>
  <a href="https://www.drachenfest-muensingen.de" (click)="closeMenu()">Drachenfest</a>
  <a href="https://www.lsv-muensingen.de/home#veranstaltungen" (click)="closeMenu()">Mietanfrage</a>
</div>
