<div  class="container backgroundImg" >
  <div id="text-block">
    <p class="img1one animate__animated animate__fadeInLeft animate__delay-1s animate__fast">Willkommen</p>
    <p class="img1two animate__animated animate__fadeInDown animate__delay-2s animate__fast">beim</p>
    <p class="img1three animate__animated animate__fadeInUp animate__delay-3s animate__fast">Luftsportverein Münsingen-Eisberg e.V.</p>
  </div>
  <!-- Banner für 1 Mai und Drachenfest auf Startseite
  <app-banner></app-banner>
  -->
</div>
