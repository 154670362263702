<lib-header></lib-header>
<div class="container">
  <h1>Impressum</h1>
  <h3>Angaben gemäß § 5 TMG:</h3>
  <p>Luftsportverein Münsingen-Eisberg e.V.</p>
  <p>Peter-Schwenk-Weg</p>
  <p>72525 Münsingen-Dottingen</p>
  <h3>Vertreten durch:</h3>
  <p>Vorstand: Peter Hinkel</p>
  <p>1. Stellvertreter: Oliver Schwenk</p>
  <p>2. Stellvertreter: Markus Paulik</p>
  <p>Schriftführer: Kai Beerhenke</p>
  <p>Kassier: Andreas Hittinger</p>
  <h3 id="contact">Kontakt (Flugplatz):</h3>
  <p>Telefon: +497381 8466</p>
  <p>Mobile: +49 151 52745126</p>
  <p><span style="color: #f2f2f2">Mobile:</span> +49 160 4633336</p>
  <p>E-Mail: <img src="../../assets/images/mailAdresses/info.svg"></p>
  <p>Web: www.lsv-muensingen.de</p>
  <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
  <p>Peter Hinkel</p>
  <h3>Quellenangaben für die verwendeten Bilder und Grafiken:</h3>
  <p>Oliver Schwenk</p>
  <p>Hannah Schuler</p>
  <h3>Technische Realisierung</h3>
  <p>Oliver Schwenk</p>
</div>
