import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anfahrt-anflug',
  templateUrl: './anflug.component.html',
  styleUrls: ['./anflug.component.css']
})
export class AnflugComponent implements OnInit {
  public isMobile = false;
  constructor() { }

  ngOnInit(): void {
    this.isMobile = (window.innerWidth <= 991) ? true : false;
  }

}
