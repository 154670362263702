import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-webcam-cards',
  templateUrl: './webcam-cards.component.html',
  styleUrls: ['./webcam-cards.component.css']
})
export class WebcamCardsComponent implements AfterViewInit, OnInit {
  linkUpdate;
  linkOst = 'https://www.luftsportverein-muensingen.de/webcam/images/6D01191PAG42FE8/Snapshot/webcamost.jpg?';
  linkWest = 'https://www.luftsportverein-muensingen.de/webcam/images/6D01191PAG58813/Snapshot/webcamwest.jpg?';
  webcamDefault = 'assets/images/webcamDefault.jpg';
  @ViewChild('webcamwest') webcamwest: ElementRef;
  @ViewChild('webcamost') webcamost: ElementRef;
  constructor() { }

  ngAfterViewInit() {
  //   this.imgWidth = this.webcamwest.nativeElement.width;
  //   this.webcamwest.nativeElement.height = this.imgWidth * .75;
  }
  ngOnInit(): void {
    this.linkUpdate = setInterval(() => {
      this.linkOst = 'https://www.luftsportverein-muensingen.de/webcam/images/6D01191PAG42FE8/Snapshot/webcamost.jpg?' + new Date().getHours() + ':' + new Date().getMinutes();
      this.linkWest = 'https://www.luftsportverein-muensingen.de/webcam/images/6D01191PAG58813/Snapshot/webcamwest.jpg?' + new Date().getHours() + ':' + new Date().getMinutes();
    }, 300000);
  }
  onResize(event) {
    /*this.imgWidth = this.webcamwest.nativeElement.width;
    this.webcamwest.nativeElement.height = this.imgWidth * .75;*/
  }

  // backupUrl(name: string): void {
  //   if (name === this.linkWest) {
  //     this.linkWest = this.webcamDefault;
  //   } else if (name === this.linkOst) {
  //     this.linkOst = this.webcamDefault;
  //   }
  // }
}

