<div class="Flugzeug">
  <img src="{{ details.img }}">
  <h3>{{ details.name }}</h3>
  <div class="line"></div>
  <div *ngIf="showContent" [@growInShrinkOut]>
    <div *ngFor="let item of description | keyvalue; index as i" class="line">
      <p class="TDlinks">{{ description[i] }}</p>
      <p class="TDrechts">{{ planeDetails[i+2]}}</p>
    </div>
  </div>
  <h4 class="button" (click)="readMore()">{{buttonContent}}</h4>
</div>

<!--
<p class="TDlinks">{{ cardDescription[i] }}</p>

-->
