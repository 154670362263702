<div class="container">
  <app-headline [headline]="'Veranstaltungen'"></app-headline>
  <swiper></swiper>
  <div  class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide oldtimer">
        <img src="/assets/images/oldtimer.jpg">
        <a href="https://www.oldtimerfest-muensingen.de"></a>
      </div>
      <div class="swiper-slide drachenfest">
        <img src="/assets/images/drachenfest/drachenfest.jpg">
        <a href="https://www.drachenfest-muensingen.de"></a>
        <!-- <div class="canceled"><h1>ABGESAGT</h1></div> -->
      </div>
      <div class="swiper-slide mietanfrage">
        <img src="/assets/images/drachenfest/drachenfest.jpg" class="hidden">
        <div class="text-wrapper">
          <h2>Mietanfrage</h2>
          <div class="gap-line"></div>
          <p>für Veranstaltungen in unserem Flugzeug Hangar oder auf unserem gesamten Fluggelände</p>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</div>
