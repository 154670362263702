import { AfterContentChecked, Component } from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})
export class DatenschutzComponent implements AfterContentChecked {

  constructor() { }
  ngAfterContentChecked(): void {
  }

}
