import { Component, OnInit } from '@angular/core';
import {Planes} from '../plane/planes.model';
import {PLANE_DESCRIPTION,  MOTOR_PLANE_LIST} from '../plane/planes.data';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-motor-plane-cards',
  templateUrl: './motor-plane-cards.component.html',
  styleUrls: ['./motor-plane-cards.component.css']
})
export class MotorPlaneCardsComponent implements OnInit {
  motorList: Planes[] = MOTOR_PLANE_LIST;
  planeDescription = PLANE_DESCRIPTION;
  public hideSwiper = false;
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    updateOnWindowResize: true,
    freeMode: true,
    slidesOffsetAfter: 310,
  };
  constructor() { }

  ngOnInit(): void {
    if (window.innerWidth <= 350) {
      this.config = {...this.config, width: 500, slidesPerView: 2, slidesOffsetAfter: 270};
      this.hideSwiper = true;
    } else if (window.innerWidth <= 500) {
      this.config = {...this.config, width: 600, slidesPerView: 2, slidesOffsetAfter: 310};
      this.hideSwiper = true;
    } else if (window.innerWidth <= 700) {
      this.config = {...this.config, width: 800, slidesPerView: 2, slidesOffsetAfter: 310};
      this.hideSwiper = true;
    } else if (window.innerWidth < 1680) {
      this.config = {...this.config, width: 800, slidesPerView: 2, slidesOffsetAfter: 150, navigation: true, pagination: {el: '.swiper-pagination', clickable: true}};
      this.hideSwiper = true;
    } else {
      this.config = {...this.config, navigation: true, pagination: {el: '.swiper-pagination', clickable: true}};
    }
    console.log(this.hideSwiper);
  }
}
