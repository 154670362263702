import { Component } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  styles: ['']
})

export class AppComponent {
  title = 'lsv-website';

}

